import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  ChakraProps,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  Account,
  AggregatePosition,
  Journal,
  Order,
  Transfer,
} from "../../api/types";
import { FiDownload } from "react-icons/fi";
import { CSVDownload } from "react-csv";
import { getAmplitude } from "../../globals/amplitude";

type ExportDataType =
  | Account
  | Journal
  | Order
  | Transfer
  | AggregatePosition
  | (string | moment.Moment)[];

enum DownloadStatus {
  ALL = "all",
  FILTERED = "filtered",
  NONE = "none",
}

interface ExportButtonProps extends ChakraProps {
  data: ExportDataType[];
  allData: ExportDataType[];
  givenHeaders?: string[];
  amplitudeEvent?: string;
}

const ExportToCsv = (props: ExportButtonProps): React.ReactElement => {
  const { data, givenHeaders, allData, amplitudeEvent } = props;
  const [downloading, setDownloading] = useState<DownloadStatus>(
    DownloadStatus.NONE
  );

  const textColor = useColorModeValue("black", "white");

  const amplitudeTracking = () => {
    if (amplitudeEvent) {
      getAmplitude().track({
        event_type: amplitudeEvent,
      });
    }
  };

  let headers: string[] = [];
  if (data.length > 0 && !givenHeaders) {
    headers = formatData(data[0], [], "");
  }

  if (props.givenHeaders) {
    headers = givenHeaders as string[];
  }

  // reset after clicking - this is necessary since CSVDownload needs to mount for the download to trigger
  useEffect(() => {
    setDownloading(DownloadStatus.NONE);
  }, [downloading]);

  return (
    <Box h="fit-content" alignSelf="center">
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<FiDownload />}
          bgColor="transparent"
          color={textColor}
          variant="ghost"
          disabled={!data.length}
        >
          Export
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setDownloading(DownloadStatus.FILTERED);
              amplitudeTracking();
            }}
          >
            Filtered View
            {downloading === DownloadStatus.FILTERED && (
              <CSVDownload data={data} target="_blank" headers={headers} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDownloading(DownloadStatus.ALL);
              amplitudeTracking();
            }}
          >
            All Data
            {downloading === DownloadStatus.ALL && (
              <CSVDownload data={allData} target="_blank" headers={headers} />
            )}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

// recursively format the headers for the CSV file looking for nested objects
const formatData = (
  data: ExportDataType,
  headers: string[],
  nestedLevel: string
): string[] => {
  for (const [key, value] of Object.entries(data)) {
    const currKey = nestedLevel ? `${nestedLevel}.${key}` : key;
    if (!!value && value.constructor === Object) {
      formatData(value, headers, currKey);
    } else {
      headers.push(currKey);
    }
  }

  return headers;
};

export default ExportToCsv;
