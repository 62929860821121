import styled from "styled-components";
import colors from "../theme/colors";
import ChangePasswordModal from "../auth/ChangePassword";
import useWindowDimensions from "../../globals/windowdim";

import React, { useState, useContext, useEffect } from "react";

import {
  Text,
  Flex,
  Button,
  Avatar,
  AvatarBadge,
  HStack,
  Box,
  Divider,
  Collapse,
  useColorModeValue,
  useColorMode,
  Menu,
  MenuItem as SelectItem,
  MenuButton,
  MenuList,
  Spacer,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom";
import { getSelf } from "../../api/api";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { eraseCookie } from "../../globals/utils";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { EventType, getAmplitude } from "../../globals/amplitude";
import { AppContext, Correspondent } from "../../globals/appcontext";

const AccountMenu = styled(Box)`
  padding: 1rem;
  margin-top: -1.2rem;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;

  ${(props) =>
    props.$open &&
    `cursor: pointer;
     background: ${props.$bg};
     box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.14);
     z-index: 2;
  `};
`;

const MenuItem = styled(Text)`
  display: flex;
  margin: 5px 2px;
  padding-top: 6px;
  &:hover {
    font-weight: bold;
  }
`;

const MenuDot = styled(Text)`
  border-radius: 100px;
  padding: 5px;
  width: 1px;
  height: 1px;
  margin-right: 9px;
`;

const EnvSelect = styled(Button)`
  && {
    padding: 0px 10px;
    font-weight: 600;
  }
`;

interface HeaderProps {
  title: string;
  showCompleteProfile?: boolean;
  hideBanner?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = (props: HeaderProps): React.ReactElement => {
  const navigate = useNavigate();
  const { data: authUser } = useQuery("self", () => getSelf());

  const [menuOpen, setMenuOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const isMobile = useWindowDimensions() === "mobile";

  // global context
  const appContext = useContext(AppContext);
  let env = appContext.correspondent?.Env || "sandbox";
  const cid = appContext.correspondent.ID;

  const getEnvColor = (env: string, status: string) => {
    // technically sandbox can still be limited
    if (status === "limited") {
      return colors.lightOrange;
    }

    if (env === "live") {
      return colors.lightGreen;
    }
    return colors.lightBlue;
  };

  // theme
  const bg = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );
  const color = useColorMode();
  const colorModeText = useColorModeValue("Dark mode", "Light mode");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  // 0 - Toggle to Light Mode; 1 - Toggle to Dark Mode
  const toggleColorMode = () => {
    color.toggleColorMode();
    getAmplitude().track({
      event_type: EventType.COLOR_MODE_BUTTON_CLICKED,
      event_properties: {
        url: "https://broker-app.alpaca.markets/dashboard",
        button: "light_dark_mode",
        value: color.colorMode === "light" ? 1 : 0,
      },
    });
  };

  const changeEnv = (newCorrespondent: Correspondent) => {
    appContext.updateCorrespondent(newCorrespondent);
    env = newCorrespondent.Env || "sandbox";
  };

  const signOut = async () => {
    eraseCookie("paciamAccessToken");
    eraseCookie("paciamRefreshToken");
    localStorage.removeItem("active_correspondent");
    localStorage.removeItem("quick-start-session");
    window.location.href = "/login?signedOut=true";
  };

  // check for any live environments
  let noLiveEnv = true;
  for (const c of authUser?.correspondents || []) {
    if (!c.sandbox) {
      noLiveEnv = false;
    }
  }

  const goLiveSubmitted = localStorage.getItem("go-live-submitted") !== null;

  const showActivateMenu = noLiveEnv && !goLiveSubmitted && isMobile;
  const showLaunchGuide = noLiveEnv && !isMobile;

  const showCompleteProfile = props.showCompleteProfile;

  // set user id in amplitude
  useEffect(() => {
    authUser && localStorage.setItem("userId", authUser.id);
  }, [authUser]);

  return (
    <>
      <ChangePasswordModal
        isOpen={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      />
      <Box position="relative">
        <Flex mt="0.5rem" mb="1.5rem" mr="90px">
          {!isMobile && (
            <Text fontSize="34px" fontWeight="bold" flex="1">
              {props.title}
            </Text>
          )}
          <Spacer />
          {showLaunchGuide && (
            <Button
              flex="1"
              onClick={() => navigate("/launch-guide")}
              minWidth="125px"
              maxWidth="125px"
            >
              Launch Guide
            </Button>
          )}
          <Menu>
            <MenuButton
              zIndex="1"
              variant="unstyled"
              color="black"
              as={EnvSelect}
              rightIcon={<ChevronDownIcon />}
              ml="1.5rem"
              minWidth="125px"
              backgroundColor={getEnvColor(
                env,
                appContext.correspondent.Status || ""
              )}
            >
              <Text display="inline" mr="15px">
                {env.charAt(0).toUpperCase() + env.slice(1)}
              </Text>
            </MenuButton>
            <MenuList>
              {authUser?.correspondents?.map((c) => (
                <SelectItem
                  key={`${c.correspondent}-${c.sandbox}`}
                  onClick={() =>
                    changeEnv({
                      ID: c.correspondent,
                      Env: c.sandbox ? "sandbox" : "live",
                    })
                  }
                  fontWeight={cid === c.correspondent ? "800" : "400"}
                >
                  <MenuDot
                    backgroundColor={getEnvColor(
                      c.sandbox ? "sandbox" : "live",
                      c.status || ""
                    )}
                  ></MenuDot>
                  {`${c.sandbox ? "Sandbox" : "Live"} - ${c.correspondent}`}
                </SelectItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        {isMobile && (
          <Text fontSize="28px" fontWeight="bold" ml="1rem">
            {props.title}
          </Text>
        )}
        <AccountMenu
          $bg={bg}
          $open={menuOpen}
          onMouseLeave={() => setMenuOpen(false)}
        >
          <HStack>
            <Text mr="7" w="100%">
              {menuOpen && "Account"}
            </Text>
            <Avatar
              onClick={() => setMenuOpen(true)}
              onMouseEnter={() => setMenuOpen(true)}
              icon={<AiOutlineUser fontSize="1.5rem" />}
            >
              {showCompleteProfile && (
                <AvatarBadge
                  bg={colors.lightOrange}
                  boxSize="0.8em"
                  mb="70%"
                  borderColor={colors.bgDark}
                  borderWidth="2px"
                />
              )}
            </Avatar>
          </HStack>
          <Collapse in={menuOpen} animateOpacity>
            <Divider m="10px 0px" />
            <MenuItem onClick={toggleColorMode}>
              <Box as="span" mt="3px" mr="6px">
                <SwitchIcon />
              </Box>
              {colorModeText}
            </MenuItem>
            {showActivateMenu && (
              <MenuItem>
                <NavLink to="/go-live">Go Live</NavLink>
              </MenuItem>
            )}
            <MenuItem onClick={() => setPasswordModalOpen(true)}>
              Change Password
            </MenuItem>
            {showCompleteProfile && (
              <MenuItem
                onClick={() => props.hideBanner && props.hideBanner(false)}
              >
                Complete Profile{" "}
                <MenuDot backgroundColor={colors.lightOrange} m="auto" />
              </MenuItem>
            )}
            {appContext.showMFA && (
              <MenuItem onClick={() => navigate("/mfa")}>
                Enable TOTP MFA
              </MenuItem>
            )}
            <MenuItem onClick={signOut}>Logout</MenuItem>
          </Collapse>
        </AccountMenu>
      </Box>
    </>
  );
};

export default Header;
