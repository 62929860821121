import React, { useEffect, useMemo, useState } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Cell, Column } from "react-table";
import { useQuery } from "react-query";
import Header from "../components/layout/Header";
import ExportToCsv from "../components/tables/ExportButton";
import { Table } from "@alpacahq/alpaca-component-library";

import { getAggregatePositions } from "../api/api";
import { AggregatePosition } from "../api/types";

import { fmtLCTMoney, fmtQuantity } from "../globals/utils";
import moment from "moment";
import {
  FilterButton,
  FilterDrawer,
  FilterSelect,
  FilterString,
} from "../components/filter";

const currency = "USD";

const Assets = (): React.ReactElement => {
  const [filteredList, setFilteredList] = useState<AggregatePosition[]>([]);

  const useFilter = () => {
    const [symbol, setSymbol] = useState("");
    const [open, setOpen] = useState(false);
    const [apply, setApply] = useState(false);

    const data = useMemo(() => {
      return { symbol, open, apply };
    }, [symbol, open, apply]);

    return { filter: data, setSymbol, setOpen, setApply };
  };

  const { filter, setSymbol, setOpen, setApply } = useFilter();

  const { data = [], isLoading } = useQuery(
    "aggregate-positions",
    () =>
      // fetch works w/ any weekday
      getAggregatePositions(getLatestWeekday()),
    {
      onSuccess: (agg) => setFilteredList(agg),
      staleTime: 5000,
    }
  );

  const options = Array.from(new Set(data.map((a) => a.symbol.toUpperCase())));

  const removeFilter = () => {
    setSymbol("");
    setApply(false);
    setFilteredList(data);
  };

  const filterPills = {
    symbol: filter.symbol,
  };

  useEffect(() => {
    if (!filter.apply || !filter.symbol) {
      return;
    }
    setFilteredList(
      data.filter((a) => a.symbol.toUpperCase() === filter.symbol.toUpperCase())
    );
  }, [filter.apply]);

  const columns: Column<AggregatePosition>[] = [
    {
      Header: "Symbol",
      accessor: ({ symbol }) => symbol,
      Cell: ({ value }: Cell) => <Text>{value}</Text>,
    },
    {
      Header: "Class",
      accessor: ({ asset_type }) => asset_type,
      Cell: ({ value }: Cell) => <Text>{value}</Text>,
    },
    {
      Header: "Closing Price",
      accessor: ({ closing_price }) => closing_price,
      Cell: ({ value }: Cell) => <Text>{fmtLCTMoney(value, currency)}</Text>,
    },
    {
      Header: "Close Price Date",
      accessor: ({ close_price_date }) => close_price_date,
      Cell: ({ value }: Cell) => <Text>{value}</Text>,
    },
    {
      Header: "Long Qty",
      accessor: ({ long_qty }) => long_qty,
      Cell: ({ value }: Cell<AggregatePosition, string>) => (
        <Text>{fmtQuantity(value)}</Text>
      ),
    },
    {
      Header: "Total Long Market Value",
      accessor: ({ long_market_value }) => long_market_value,
      Cell: ({ value }: Cell<AggregatePosition, string>) => (
        <Text>{fmtLCTMoney(value, currency)}</Text>
      ),
    },
    {
      Header: "Short Qty",
      accessor: ({ short_qty }) => short_qty,
      Cell: ({ value }: Cell<AggregatePosition, string>) => (
        <Text>{fmtQuantity(value)}</Text>
      ),
    },
    {
      Header: "Total Short Market Value",
      accessor: ({ short_market_value }) => short_market_value,
      Cell: ({ value }: Cell<AggregatePosition, string>) => (
        <Text>{fmtLCTMoney(value, currency)}</Text>
      ),
    },
  ];

  return (
    <Box>
      <Header title="Transactions > Assets" />
      <Text mt="2rem" opacity="0.6">
        The table below lists all assets that are held by your customers
        currently.
      </Text>
      <Flex justifyContent="end">
        <ExportToCsv data={filteredList} allData={data} />
        <FilterButton
          filterPills={filter.apply ? filterPills : {}}
          openFilter={() => {
            setOpen(true);
            setApply(false);
          }}
          removeFilter={() => removeFilter()}
        />
      </Flex>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={filteredList}
        defaultPerPage={25}
        pagination
        paginationPosition="top"
      />
      <FilterDrawer
        isOpen={filter.open}
        onClose={() => setOpen(false)}
        onApply={() => {
          setApply(true);
          setOpen(false);
        }}
      >
        {options.length > 15 ? (
          <>
            <FilterString
              header="Symbol"
              onChange={(value) => setSymbol(value)}
              list="asset-symbol-list"
            />
            <datalist id="asset-symbol-list">
              {options.map((option, index) => (
                <option key={`${index}-${option}`} value={option}>
                  {option}
                </option>
              ))}
            </datalist>
          </>
        ) : (
          <FilterSelect
            header="Symbol"
            options={options}
            onSelect={setSymbol}
            selected={filter.symbol}
          />
        )}
      </FilterDrawer>
    </Box>
  );
};

const getLatestWeekday = (): string => {
  const date = moment().tz("America/New_York");
  const day = date.day();
  // if weekend set to last friday
  if ([0, 6].includes(day)) {
    date.subtract(day === 0 ? "2" : "1", "days");
  }
  return date.format("YYYY-MM-DD");
};

export default Assets;
