import React from "react";
import { Badge, Box } from "@chakra-ui/react";
import { Column } from "react-table";
import { Table } from "@alpacahq/alpaca-component-library";
import { useQuery } from "react-query";
import { getCorrespondentIPAllowlist } from "../../api/api";
import { CIDR } from "../../api/types";

const columns: Column<CIDR>[] = [
  {
    Header: "CIDR",
    accessor: "cidr",
  },
];

const formatDataToColumns = (data: string[]): CIDR[] =>
  data.map((value) => ({ cidr: value }));

const IPAllowlist = (): React.ReactElement => {
  const { data: ipAllowlist, isLoading } = useQuery(
    ["correspondent", "allowed-cidr"],
    () => getCorrespondentIPAllowlist()
  );

  const enabled = ipAllowlist?.enabled ?? false;
  const cidrs = ipAllowlist?.cidrs ?? [];

  return (
    <Box>
      <Badge colorScheme={enabled ? "green" : "red"} marginBottom="1rem">
        IP Allowlist {enabled ? "Enabled" : "Disabled"}
      </Badge>
      <Table
        className="striped"
        columns={columns}
        data={formatDataToColumns(cidrs)}
        defaultPerPage={cidrs.length}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default IPAllowlist;
