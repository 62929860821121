import React from "react";
import {
  Box,
  BoxProps,
  ScaleFade,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";
import colors from "../theme/colors";

interface CardProps extends BoxProps {
  children: React.ReactElement | React.ReactElement[];
  boxShadow?: string;
  isLoading?: boolean;
  noAnimate?: boolean;
  height?: string;
}

const Card = (props: CardProps): React.ReactElement => {
  const color = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );

  // don't pass unrecognized props to DOM
  const extraProps = { ...props };
  if ("isLoading" in extraProps) {
    delete extraProps.isLoading;
  }

  const card = (
    <Box
      {...extraProps}
      bg={color}
      borderRadius="8px"
      p="1rem"
      height={props.height}
    >
      {props.isLoading ? (
        <Skeleton h={props.height} isLoaded={!props.isLoading}>
          {props.children}
        </Skeleton>
      ) : (
        props.children
      )}
    </Box>
  );

  if (props.noAnimate) {
    return card;
  }

  return (
    <ScaleFade style={{ height: props.height }} initialScale={0.9} in>
      {card}
    </ScaleFade>
  );
};

export default Card;
