import React, { useState, useEffect } from "react";

import { getCookie } from "./utils";
import { useIntercom } from "react-use-intercom";
import { CognitoUser } from "@aws-amplify/auth";
import { useQueryClient } from "react-query";
import { TeamMember, BdData } from "../api/types";
import { refreshPaciamToken } from "../auth/paciam";
import {
  getCorrespondent,
  getSelf,
  setEnv,
  setAuthHeader,
  getIntercomUserHash,
} from "../api/api";

export interface Correspondent {
  ID?: string;
  Env?: string;
  Description?: string;
  BusinessType?: string;
  UserCountries?: string[];
  Name?: string;
  Setup?: string;
  Status?: string;
  BdData?: BdData;
}

const ckey = "active_correspondent";
export const getC = (): Correspondent => {
  const stored = localStorage.getItem(ckey);
  return JSON.parse(stored ?? "{}") as Correspondent;
};

const emptyCorrespondent: Correspondent = {
  ID: "",
  Env: "sandbox",
  BusinessType: "",
  Setup: "",
};

interface Context {
  correspondent: Correspondent;
  isLoading: boolean;
  cognitoUser?: CognitoUser;
  authenticated: boolean;
  showMFA: boolean;
  setIsAuthenticated(isAuthenticated: boolean): void;
  setCognitoUser(user: CognitoUser): void;
  setIsLoading(isLoading: boolean): void;
  updateCorrespondent(correspondent?: Correspondent): void;
  clear(): void;
  self: TeamMember | undefined;
}

export const AppContext = React.createContext<Context>({} as Context);

interface AppContextProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const AppContextProvider = (
  props: AppContextProviderProps
): React.ReactElement => {
  const local = getC();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setIsAuthenticated] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [self, setSelf] = useState<TeamMember>();
  const [
    activeCorrespondent,
    setActiveCorrespondent,
  ] = React.useState<Correspondent>(local || emptyCorrespondent);
  const queryClient = useQueryClient();
  const { boot } = useIntercom();

  useEffect(() => {
    if (!authenticated) return;
    getSelf().then((teamMember) => {
      localStorage.setItem("user-role", teamMember.role || "");
      setSelf(teamMember);
    });
  }, [authenticated]);

  useEffect(() => {
    if (!self) return;
    // Boot intercom.
    getIntercomUserHash()
      .then((userHash) => {
        boot({
          name: self.name,
          email: self.email,
          userId: self.id,
          userHash,
        });
      })
      .catch((error) => {
        console.error("Intercom error", error);
      });

    const { correspondents = [] } = self;
    const correspondent =
      correspondents.find(
        (c) =>
          c.correspondent === activeCorrespondent.ID &&
          c.sandbox === (activeCorrespondent.Env === "sandbox")
      ) || correspondents[0];

    if (!correspondent) {
      updateCorrespondent(emptyCorrespondent);
      return setIsLoading(false);
    }

    const env = correspondent.sandbox ? "sandbox" : "live";
    setEnv({
      ID: correspondent.correspondent,
      Env: env,
    });

    updateCorrespondent({
      ID: correspondent.correspondent,
      Env: env,
    });

    getCorrespondent()
      .then((c) => {
        updateCorrespondent({
          ID: c.correspondent,
          BusinessType: c.business_type,
          Env: env,
          Name: c.name,
          Setup: c.setup,
          Status: c.status,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [self]);

  useEffect(() => {
    setEnv(activeCorrespondent);
    const cachedCorrespondent = getC();
    if (
      activeCorrespondent.ID !== cachedCorrespondent.ID ||
      activeCorrespondent.Env !== cachedCorrespondent.Env
    ) {
      localStorage.setItem(ckey, JSON.stringify(activeCorrespondent));
      refreshPaciamToken(getCookie("paciamRefreshToken")).then(() => {
        queryClient.clear();
        queryClient.resetQueries();
        // TODO: can remove this hard reload when we remove dependency on embedded correspondent in Paciam claims
        window.location.reload();
      });
    }
  }, [activeCorrespondent]);

  useEffect(() => {
    const mfaCheck = localStorage.getItem("show-mfa");

    if (mfaCheck === null) {
      const mfa = self?.mfa_type || "";
      if (mfa !== "totp") {
        setShowMFA(true);
        localStorage.setItem("show-mfa", "true");
      } else {
        localStorage.setItem("show-mfa", "false");
      }
    } else {
      setShowMFA(mfaCheck === "true");
    }
  }, [self]);

  const updateCorrespondent = (newCorrespondent?: Correspondent) => {
    if (!newCorrespondent) {
      return setActiveCorrespondent(emptyCorrespondent);
    }
    setActiveCorrespondent({ ...activeCorrespondent, ...newCorrespondent });
  };

  const clear = () => {
    setActiveCorrespondent(emptyCorrespondent);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // avoid refreshing auth headers in these routes as it will error and re-direct to login
      const nonAuthRoutes = [
        "/login",
        "/sign-up",
        "/forgot-password",
        "/set-password",
        "/verification",
      ];
      if (!nonAuthRoutes.includes(window.location.pathname)) {
        setAuthHeader();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <AppContext.Provider
      value={{
        correspondent: activeCorrespondent,
        clear,
        updateCorrespondent,
        isLoading,
        cognitoUser,
        setCognitoUser,
        authenticated,
        setIsAuthenticated,
        setIsLoading,
        showMFA,
        self,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
